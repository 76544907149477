<template>
	<div>
		<Header ref="Header" />
		<div class="bigbox">
			<div class="index-picbox">
				<img class="index-pic_01" :src="menuPic" />
			</div>
			<div class="smallbox">
				<div class="index-nav flex">
					<div class="index-nav-item flex">
						<el-select v-model="data.city" placeholder="北京" @change="chooseCity">
							<el-option v-for="(item,index) in city" :key="index" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</div>
					<div class="index-nav-item flex">
						<el-select v-model="data.type" placeholder="场地类型" @change="chooseType">
							<el-option v-for="(item,index) in type" :key="index" :label="item.dictLabel" :value="item.dictSort">
							</el-option>
						</el-select>
					</div>
					<div class="index-nav-item flex">
						<el-select v-model="data.capacity" placeholder="容纳人数" @change="chooseCapacity">
							<el-option v-for="(item,index) in capacity" :key="index" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</div>
					<div class="index-nav-item flex">
						<el-select v-model="data.size" placeholder="场地面积" @change="chooseSize">
							<el-option v-for="(item,index) in size" :key="index" :label="item.name" :value="item.name">
							</el-option>
						</el-select>
					</div>
					<div>
						<div class="index-nav-serch" @click="search()">搜索</div>
					</div>
				</div>
				<div class="index-sort-box flex">
					<div class="index-sort-nav">
						<div class="index-sort-tiltle">行业分类</div>
						<div class="index-sort-list">
							<div v-for="(item,index) in sort" :key="index">
								<div @click="sortSwitch(index,item.dictLabel)" :class="sortIndex==index?'index-sort-item2':'index-sort-item'">
									{{item.dictLabel}}</div>
								<img class="index-sort-boder" v-if="sortIndex==index" src="../../public/images/boder_01.png" />
							</div>
						</div>
					</div>
					<div class="index-hot">
						<h2 class="index-hot-title">热门分类</h2>
						<div class="index-hot-tips">CLSSIFY</div>
						<div class="index-hot-list flex">
							<div v-for="(item,index) in hot" :key="index">
								<div class="index-hot-item jump" @click="toHotSort(item.type)">
									<img class="index-hot-pic" :src="item.picture" />
									<div class="index-hot-name">{{item.name}}</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="index-topic-box">
					<h2 class="index-tilte">专题推荐</h2>
					<div class="index-tips">SPECIAL</div>
					<div class="index-topic-picbox">
						<el-carousel :interval="3000" type="card" height="400px"
						arrow="never" indicator-position="none" ref="bigSwiper" >
						    <el-carousel-item v-for="(item,index) in topic" :key="index">
								<img :src="item.picture" class="big-swiper-img" @click="topicJump(item)"/>
						    </el-carousel-item>
						</el-carousel>
					</div>
					<div class="flex big-swiper-button">
						<img class="big-swiper-button-left jump" src="../../public/images/left_01.png" @click="bigSwitch('L')" />
						<img class="big-swiper-button-left jump" src="../../public/images/right_01.png"  @click="bigSwitch('R')"/>
					</div>
				</div>
				<div class="index-space-box">
					<h2 class="index-tilte">精选场地</h2>
					<div class="index-tips">SPACE</div>
					<div class="index-space-more jump" @click="pageTo('space')">更多精选场地</div>
					<div class="index-space-list flex">
						<div v-for="(item,index) in space" :key="index">
							<div class="index-space-item" @click="toSpace(item.id)">
								<img class="index-space-pic" :src="item.picture" />
								<div class="index-space-name">【{{item.city}}】{{item.name}}</div>
								<!-- <div class="index-space-price flex" v-if="userInfo.id">
									<div class="index-space-num">￥{{item.price}}</div>
									<div class="index-space-qi">{{item.priceUnit}}</div>
								</div> -->
								<div class="index-space-btnbox flex">
									<div v-for="(item,index) in item.mark">
										<div class="index-space-btn">{{item}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="index-service-box">
					<h2 class="index-tilte">服务案例</h2>
					<div class="index-tips">SERVICE</div>
					<div class="index-service-btnbox flex">
						<div v-for="(item,index) in serviceBtn" :key="index">
							<div @click="servicSwitcth(index)" class="jump"
								:class="serviceIndex==index?'index-service-btn1':'index-service-btn'">
								{{item.name}}
							</div>
						</div>
					</div>
					<div class="index-service-list">
						<el-carousel :interval="2000" height="150px" :autoplay="false"
						arrow="never" indicator-position="none" ref="serviceSwiper" >
						    <el-carousel-item v-for="(item,index) in serviceswiper" :key="index">
								<div class="index-service-line">
									<div v-for="(unit,index) in item.list" :key="index">
										<img :src="unit.picture" class="serviceSwiper-img jump" @click="articleJump(unit,3)"/>
										<div class="serviceSwiper-title jump">{{unit.title}}</div>
									</div>
								</div>
						    </el-carousel-item>
						</el-carousel>
						<div class="switch flex">
							<div class="switch-button jump"  @click="servicSwitcthPic('L')">
								<img class="arrow" src="../../public/images/arrow_04.png" />
							</div>
							<div class="switch-button jump" @click="servicSwitcthPic('R')">
								<img class="arrow" src="../../public/images/arrow_05.png" />
							</div>
						</div>
						<div class="nav">
							<div v-for="(item,index) in serviceswiper" :key="index">
								<div class="navItem">
									<img src="../../public/images/boder_02.png" :class="boderIndex==index?'boder1':'boder'"/>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="index-news-box">
					<h2 class="index-tilte">最新动态</h2>
					<div class="index-tips">NEWS</div>
					<div class="index-news-list flex">
						<div class="index-news-left">
							<div class="index-news-pic">
								<!-- <el-carousel :interval="4000" height="243px" ref="newsSwiper" arrow="always" >
								    <el-carousel-item v-for="(item,index) in newsSwiper" :key="index">
										<img :src="item.picture" class="index-news-pic jump" @click="articleJump(item,5)"/>
								    </el-carousel-item>
								</el-carousel> -->
								<img :src="newsLeft.picture" class="index-news-pic jump" @click="articleJump(newsLeft,5)"/>
							</div>
							<div v-if="newsLeft.id" class="index-news-item flex" @click="articleJump(newsLeft,5)">
								<div class="index-news-time">{{newsLeft.createTime}}</div>
								<div class="index-news-desc">
									<div class="index-news-title">{{newsLeft.title}}</div>
									<div class="index-news-text">{{newsLeft.remark}}</div>
								</div>
							</div>
						</div>
						<div class="index-news-right">
							<div v-for="(item,index) in news" :key="index">
								<div class="index-news-item1 flex" @click="articleJump(item,5)">
									<div class="index-news-time1">{{item.createTime}}</div>
									<div class="index-news-desc1">
										<div class="index-news-title"> {{item.title}}</div>
										<div class="index-news-text">{{item.remark}}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div class="index-partner-box">
					<h2 class="index-tilte">合作品牌</h2>
					<div class="index-tips">PARTNER</div>
					<div class="index-partner-list">
						<el-carousel :interval="2000" height="80px"
						arrow="never" indicator-position="none" ref="logoSwiper" >
						    <el-carousel-item v-for="(item,index) in logo" :key="index">
								<div class="logo-line">
									<div v-for="(unit,index) in item.list" :key="index">
										<a :href="unit.link">
											<img :src="unit.logo" class="logoSwiper-img" 
											:class="index<(item.list.length-1)?'logoSwiper-margin':''"/>
										</a>
									</div>
								</div>
						    </el-carousel-item>
						</el-carousel>
						<div class="logo-switch flex">
							<div class="switch-button jump"  @click="logoSwitcth('L')">
								<img class="arrow" src="../../public/images/arrow_04.png" />
							</div>
							<div class="switch-button jump" @click="logoSwitcth('R')">
								<img class="arrow" src="../../public/images/arrow_05.png" />
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import {
		mapState
	} from 'vuex';
	import Header from '@/components/header.vue';
	export default {
		components: {
			Header
		},
		data() {
			return {
				showPrice:false,
				menuPic:'',
				sortIndex: 0,
				serviceIndex: 0,
				boderIndex:0,
				data:{
					type:'',
					capacity:'',
					size:'',
				},
				// 场地查询传参
				postData:{},
				city: [],
				type: [],
				capacity: [
					{name:'不限',capacityMin:0,capacityMax:0,},
					{name:'20人以下',capacityMin:0,capacityMax:20,},
					{name:'20-50人',capacityMin:20,capacityMax:50,},
					{name:'50-100人',capacityMin:50,capacityMax:100,},
					{name:'100-300人',capacityMin:100,capacityMax:300,},
					{name:'300-500人',capacityMin:300,capacityMax:500,},
					{name:'500人以上',capacityMin:500,capacityMax:0,}
				],
				size: [
					{name:'不限',sizeMin:0,sizeMax:0},
					{name:'100㎡以下',sizeMin:0,sizeMax:100},
					{name:'100㎡—300㎡',sizeMin:100,sizeMax:300},
					{name:'300㎡—500㎡',sizeMin:300,sizeMax:500},
					{name:'500㎡—1000㎡',sizeMin:500,sizeMax:1000},
					{name:'1000㎡—1500㎡',sizeMin:1000,sizeMax:1500},
					{name:'1500㎡',sizeMin:1500,sizeMax:0},
				],
				sort: [],
				hot: [],
				topic:[],
				space: [],
				serviceBtn: [
					{name:'活动案例',category:1},
					{name:'行业案例',category:7},
					{name:'活动攻略',category:8},
				],
				serviceswiper: [],
				newsSwiper: [],
				newsLeft:{},
				news: [],
				logo: [],
			}
		},
		computed:{
			...mapState(['userInfo']),
		},
		async mounted() {
			this.$refs.Header.setIndex(0);
			// 获取菜单图片
			this.$api.getMenuPic('首页').then(res=>{
				console.log(res,'菜单图片');
				this.menuPic=res.data[0].picture;
			})
			let res = await this.$api.getPlaceList();
			console.log(res);
			// 获取场地类型
			this.$api.getDictType('app_place_type').then(res=>{
				console.log(res,'场地类型');
				this.type=res.rows;
			})
			// 获取行业类型
			this.$api.getDictType('app_activities_type').then(res=>{
				console.log(res,'行业分类');
				this.sort=res.rows;
			})
			// 获取热门分类
			this.$api.getHotSort().then(res=>{
				console.log(res,'热门分类');
				this.hot=res.data;
			})
			// 获取城市列表
			this.getCities();
			// 获取专题推荐
			this.getTopic();
			// 精选场地
			this.getSpace();
			// 服务案例
			this.getService();
			// 最新动态
			this.getDynamic();
			// 合作品牌
			this.getPartner();
		},
		methods: {
			sortSwitch(index,name) {
				// console.log(dictSort);
				this.sortIndex = index;
				this.$router.push({
					path: 'space',
					query:{
						activities:name,
					},
				});
			},
			// 热门分类跳转
			toHotSort(type){
				this.$router.push({
					path: 'space',
					query:{
						type:type,
					},
				});
			},
			bigSwitch(der) {
				if (der === 'L') {
					this.$refs.bigSwiper.prev();
				} else {
					this.$refs.bigSwiper.next();
				}
			},
			servicSwitcth(index){
				this.serviceIndex=index;
				this.getService(this.serviceBtn[index].category);
			},
			servicSwitcthPic(der) {
				if (der === 'L') {
					this.$refs.serviceSwiper.prev();
				} else {
					this.$refs.serviceSwiper.next();
				}
				this.boderIndex = this.$refs.serviceSwiper.activeIndex;
			},
			logoSwitcth(der){
				if (der === 'L') {
					this.$refs.logoSwiper.prev();
				} else {
					this.$refs.logoSwiper.next();
				}
			},
			pageTo(page) {
				this.$router.push({
					path: page
				});
			},
			// 获取城市列表
			getCities(){
				var data={
					orderByColumn:'letter',
					isAsc:'asc'
				}
				console.log(data,'城市传参')
				this.$api.getCityList(data).then(res => {
					console.log(res,'城市列表')
					this.city = res.data.city;
				});
			},
			// 选择城市
			chooseCity(e){
				localStorage.setItem('city',e);
				// this.postData.city=e;
			},
			// 选择场地类型
			chooseType(e){
				this.postData.type=e;
			},
			// 选择容量
			chooseCapacity(e){
				for(let i=0;i<this.capacity.length;i++){
					if(this.capacity[i].name===e){
						this.postData.capacityIndex=i
					}
				}
			},
			// 选择面积
			chooseSize(e){
				for(let i=0;i<this.size.length;i++){
					if(this.size[i].name===e){
						this.postData.sizeIndex=i;
					}
				}
			},
			//产地快捷查询
			search(){
				console.log(this.postData,'搜索传参');
				this.$router.push({
					path: 'space',
					query: this.postData
				});
			},
			// 获取专题推荐
			getTopic(){
				var data={
					pageNum:1,
					pageSize:3,
				};
				this.$api.getTopicList(data).then(res=>{
					console.log(res,'专题列表');
					this.topic=res.rows;
				});
			},
			// 获取精选场地
			getSpace(){
				var data={
					city:localStorage.getItem('city') || '北京',
					pageNum:1,
					pageSize:6,
				};
				console.log(data,'datadatadata');
				this.$api.getPlaceList(data).then(res=>{
					console.log(res,'精选场地')
					for(let i=0 ;i<res.rows.length;i++){
						res.rows[i].mark=res.rows[i].mark.split(',');
						res.rows[i].mark=res.rows[i].mark.splice(0,2);
					}
					this.space=res.rows;
				})
			},
			//场地详情
			toSpace(spaceId){
				this.$router.push({
					path: 'introduction',
					query:{
						id:spaceId,
					},
				});
			},
			// 专题详情
			topicJump(item){
				if(item.linkType==1){
					this.$router.push({
						path: 'topicDetail',
						query: {
							index:2,
							id:item.id,
						},
					});
				}else if(item.linkType==2){
					window.open(item.link, '_blank');
				}else{
					return
				}
			},
			// 服务案例列表
			getService(category=1){
				var data={
					category:category,
					pageNum:1,
					pageSize:9,
				};
				this.serviceswiper=[];
				this.$api.getArticleList(data).then(res=>{
					console.log(res,'服务案例');
					res.rows=res.rows.splice(0,9)
					let max=Math.ceil(res.rows.length/3);
					for(let i=0;i<max;i++){
						if(i==0){
							this.serviceswiper.push({
								list:res.rows.splice(0,3)
							})
						}else if(i==1){
							this.serviceswiper.push({
								list:res.rows.splice(3,6)
							})
						}else{
							this.serviceswiper.push({
								list:res.rows.splice(6,9)
							})
						}
					}
				});
			},
			// 文章链接跳转
			articleJump(item,headerIndex){
				if(item.linkType==1){
					this.$router.push({
						path: 'passage',
						query: {
							index:headerIndex,
							id:item.id,
						},
					});
				}else if(item.linkType==2){
					window.open(item.link, '_blank');
				}else{
					return
				}
			},
			//获取最新动态
			getDynamic(){
				var data={
					category:4,
					pageNum:1,
					pageSize:8,
					orderByColumn:'createTime',
					isAsc:'desc',
				};
				this.$api.getArticleList(data).then(res=>{
					console.log(res,'最新动态');
					for(let i=0 ;i<res.rows.length;i++){
						res.rows[i].createTime=res.rows[i].createTime.substr(5,5)
					}
					this.newsLeft=res.rows[0];
					this.news=res.rows.slice(1,8);
				});
			},
			//合作品牌
			getPartner(){
				this.$api.getCooperator().then(res=>{
					console.log(res,'合作品牌');
					let max=Math.ceil(res.data.length/6);
					for(let i=0;i<max;i++){
						var start=parseInt(i*6);
						var end=parseInt((i+1)*6);
						this.logo.push({
							list:res.data.slice(start,end)
						})
					}
					console.log(max,this.logo,'aaaaaa',res.data);
				})
			},
		},
	}
</script>

<style scoped>
	/* 本页面swiper样式 */
	.big-swiper-img{
		width: 810px;
		height: 400px;
	}
	.big-swiper-button {
		position: absolute;
		z-index: 9;
		margin-top: -100px;
		margin-left: 25%;
		margin-bottom: 0;
		width: 92px;
		justify-content: space-between;
	}

	.big-swiper-button-left {
		width: 32px;
		height: 32px;
	}

	.big-swiper-button-right {
		width: 32px;
		height: 32px;
	}
	.index-service-line{
		width: 900px;
		height: 180px;
		display: flex;
		justify-content: space-between;
		/* border: solid 1px; */
	}
	.serviceSwiper-img{
		/* margin-right: 15px; */
		width: 285px;
		height: 120px;
		/* border: solid 1px; */
	}
	.serviceSwiper-title{
		width: 285px;
		margin-top: 15px;
		text-align: center;
		font-size: 12px;
		font-family: PingFang SC;
		font-weight: 500;
		color: #333333;
		white-space: nowrap; 
		overflow: hidden;
		text-overflow:ellipsis;
	}
	.switch {
		position: absolute;
		margin-top: -75px;
		width: 900px;
		justify-content: space-between;
		/* pointer-events:none; */
	}

	.switch-button {
		z-index: 999;
		width: 40px;
		height: 40px;
		background: #000000;
		opacity: 0.3;
	}

	.arrow {
		margin-left: 16px;
		margin-top: 14px;
		width: 7px;
		height: 12px;
	}
	.nav{
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.navItem{
		width:285px;
	}
	.boder{
		/* margin-top: 15px; */
		width: 190px;
		height: 12px;
		margin-left: 47px;
	}
	.boder1{
		/* margin-top: 15px; */
		margin-left: 47px;
		width: 190px;
		height: 12px;
		filter: invert(53%) sepia(100%) saturate(564%) hue-rotate(344deg) brightness(109%) contrast(87%);
	}
	.logo-line{
		margin: 15px auto;
		width: 860px;
		height: 50px;
		display: flex;
		flex-direction: row;
		justify-content: flex-start;
	}
	.logoSwiper-img{
		width: 120px;
		height: 50px;
		
	}
	.logoSwiper-margin{
		margin-right: 25px;
	}
	.logo-switch{
		width: 950px;
		justify-content: space-between;
		margin-left: -25px;
		margin-top: -60px;
	}
	/deep/.el-input__inner{
		/* width: 100%; */
		/* background-color: #f8f8f8; */
		background: #FFFFFF;
		box-shadow: 0px 1px 4px 0px rgba(25, 25, 25, 0.1);
		border: 0px solid #000000;
	}
</style>
